<template>
	<div>
		<div class="blog-component mini-spacer">
			<v-container>
				<!-- -----------------------------------------------
            Start Blog Text
        ----------------------------------------------- -->
				<v-row justify="center">
					<v-col cols="12" sm="10" md="9" lg="7">
						<div class="text-center">
							<h2 class="ui-title font-weight-bold">Our Destinations</h2>
							<p class="text-muted">Explore Palawan Destinations with Bookable Curated Listings</p>
						</div>
					</v-col>
				</v-row>

				<v-row class="mt-9" justify="center">
					<v-col cols="12" md="4" sm="6" v-for="dest in destinations" :key="dest.title">
						<HoverCard :imageSrc="`/images/destinations/${dest.code.toLowerCase()}.jpg`" :destinationText="dest.title" href="/transportation" />
						<!-- <NuxtLink v-if="dest.url" :to="dest.url">
							
						</NuxtLink> -->
					</v-col>
				</v-row>
			</v-container>
		</div>
	</div>
</template>

<script setup lang="ts">
import { Destinations } from '~/data/Records';
const destinations = Destinations.filter((d) => d.code !== 'AST');
</script>
